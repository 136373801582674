<template>
    <section v-if="!wide" class="collection-visual">
        <v-img :src="image" />
        <v-container>
            <v-sheet color="white">
                <v-breadcrumbs :items="breadcrumbs">
                    <template v-slot:divider>
                        <v-icon color="#333">mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>

                <div class="d-flex justify-center">
                    <div class="collection-visual__title" :class="{ 'scrolled-top': isScrolledTop }">
                        <div class="txt txt--xs font-weight-light">COLLECTION</div>
                        <h2 class="tit tit--lg font-tertiary font-weight-regular">
                            {{ title }}
                        </h2>
                    </div>
                </div>
            </v-sheet>
        </v-container>
    </section>
</template>

<script>
export default {
    props: {
        image: { type: String },
        title: { type: String },
        breadcrumbs: { type: Array, default: [] },
    },
    data: () => ({
        isScrolledTop: false,
    }),
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.isScrolledTop = window.scrollY === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.collection-visual {
    &__title {
        margin: 0 auto;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 32px 40px;
        text-align: center;
        transition: transform 0.3s ease;
        background-color: #fff;
        text-transform: uppercase;
        &.scrolled-top {
            transform: translateY(-120%);
        }
    }
}

@media (min-width: 768px) {
}
</style>