<template>
    <client-page>
        <collections-visual :image="`${path}/visual.webp`" title="ViClean" :breadcrumbs="breadcrumbs" />

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--sm text-center">화장실에 갈 때마다 깨끗하고 상쾌한 기분을 느껴 보세요. Villeroy & Boc의 ViClean 비데 변기 컬렉션은 부드럽게 앞뒤로 분사되는 워터 제트로 최적의 청소, 쾌적한 위생 및 손쉬운 조작을 제공합니다. 세련된 디자인.</div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <div class="tit text-center">당신의 새로운 안락 지대</div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <div class="tit">제품의 장점과 특징</div>
                </div>
                <section-slide :path="path" :items="features" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">ViClean 모델을 찾아보세요</div>
                        <p class="txt txt--sm">ViClean-I 100과 ViClean-I 200의 두 가지 모델은 독특한 위생과 새로운 편안함을 보장합니다. 모든 것은 개별적으로 조정될 수 있습니다. 하이라이트: 비데 변기의 편의 기능은 외부에서 볼 수 없으며 내부에 숨겨져 있습니다.</p>
                    </v-col>
                </v-row>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class="row--xxl">
                            <v-col cols="12" md="6">
                                <v-card tile flat>
                                    <v-img :src="`${path}/viclean-wc-nozzle-and-toiletrim-white.webp`" />
                                    <v-card-text>
                                        <div class="tit tit--xs mb-6px mb-md-8px">ViClean-I 100</div>
                                        <p class="txt txt--sm">ViClean-I 100은 직관적인 조작 등 위생적이고 편안한 기능 외에도 HarmonicWave 샤워젯을 통해 부드러운 파도 움직임으로 샤워 기능을 제공합니다.</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card tile flat>
                                    <v-img :src="`${path}/viclean-toilet-white-with-flush-technology.webp`" />
                                    <v-card-text>
                                        <div class="tit tit--xs mb-6px mb-md-8px">ViClean-I 200</div>
                                        <p class="txt txt--sm">ViClean-I 200은 한 단계 더 나아졌습니다. 올라운드 컴포트 패키지는 컬렉션의 기능 외에도 세탁, 드라이, 시트 난방, 사용자 인식 등의 컴포트 옵션도 제공합니다.</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <v-row justify="center" class="row--lg">
                        <v-col cols="12" md="8" class="text-center">
                            <div class="tit mb-12px mb-md-16px">Vi-Clean-I 100 대 Vi-Clean-I 200</div>
                            <p class="txt txt--sm">어떤 ViClean 비데 변기를 선택하시든 탁월한 선택입니다! 표에서 두 모델을 빠르고 쉽게 비교할 수 있습니다.</p>
                        </v-col>
                    </v-row>
                </div>

                <div class="position-relative">
                    <v-sheet color="primary" class="position-sticky px-16px px-md-40px">
                        <v-row align="center" class="row--lg white--text tit tit--sm">
                            <v-col cols="6">비데 변기 모델</v-col>
                            <v-col cols="3" class="text-center">ViClean-I 100</v-col>
                            <v-col cols="3" class="text-center">ViClean-I 200</v-col>
                        </v-row>
                    </v-sheet>
                    <v-sheet class="txt mt-16px mt-md-20px px-16px px-md-20px">
                        <template v-for="(item, index) in table">
                            <div :key="index" :class="index == 0 ? 'mb-0' : 'mt-56px mt-md-100px'">
                                <v-row class="row--lg">
                                    <v-col cols="12" class="font-weight-medium">
                                        <div class="pl-0px pl-md-20px">
                                            {{ item.text }}
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="pa-0">
                                        <v-divider />
                                    </v-col>
                                </v-row>

                                <template v-for="child in item.children">
                                    <v-row align="center" :key="child.text" class="row--lg">
                                        <v-col cols="6">
                                            <div class="pl-0px pl-md-20px">
                                                {{ child.text }}
                                            </div>
                                        </v-col>

                                        <template v-if="child.check">
                                            <v-col cols="3" class="text-center">
                                                <v-icon>
                                                    <template v-if="child.one == true">mdi-check</template>
                                                    <template v-else>mdi-close</template>
                                                </v-icon>
                                            </v-col>
                                            <v-col cols="3" class="text-center">
                                                <v-icon>
                                                    <template v-if="child.two == true">mdi-check</template>
                                                    <template v-else>mdi-close</template>
                                                </v-icon>
                                            </v-col>
                                        </template>

                                        <template v-else>
                                            <v-col cols="3" class="text-center">
                                                {{ child.one }}
                                            </v-col>
                                            <v-col cols="3" class="text-center">
                                                {{ child.two }}
                                            </v-col>
                                        </template>

                                        <v-col cols="12" class="pa-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                </template>
                            </div>
                        </template>
                    </v-sheet>
                </div>
            </v-container>
        </page-section>

        <!-- <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="txt txt--xs mb-16px mb-md-20px">만능 컴포트 패키지</div>
                        <div class="tit mb-12px mb-md-16px">ViClean-I 200의 주요 특징</div>
                        <p class="txt txt--sm">세탁, 드라이, 시트 난방, 사용 인식: ViClean-I 200의 하이라이트가 무엇인지 영상을 통해 알아보세요. 직관적인 작동 방식과 HarmonicWave 샤워 제트의 작동 방식을 살펴보세요.</p>
                        <v-btn color="primary" class="rounded-xs mt-18x mt-md-24px">
                            ViClean-I 200용
                            <v-img max-width="24" src="/images/icon/icon-arrow-right-white.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section> -->
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CollectionsVisual from "@/components/client/collections/collections-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        CollectionsVisual,
        SectionSlide,
    },
    data: () => ({
        tab: null,
        breadcrumbs: [
            {
                text: "컬렉션",
                href: "/collections/antao",
            },
            {
                text: "ViClean",
                href: "/collections/viclean",
            },
        ],
        path: "/images/collections/viclean",
        features: [
            {
                title: "부드러운 샤워 기능",
                text: "엉덩이부터 여성용 샤워, 연속 워터제트, 맥동 마사지 샤워 및 HarmonicWave까지 ViClean 비데 변기의 샤워 기능은 더 이상 바랄 것이 없습니다.",
                subtitle: "종이 대신 물",
                image: "vicleani200-shower-toilet-with-water-jet.webp",
            },
            {
                title: "더욱 스마트해진 사용 편의성",
                text: "앱을 통해 화장실을 제어하는 ​​것은 어떨까요? 앱을 통해 ViClean 비데 변기를 쉽게 작동하고, 사용자 프로필을 저장하거나 직관적인 리모콘으로 모든 기능을 편리하게 제어할 수 있습니다.",
                subtitle: "설정을 통해 컨트롤",
                image: "viclean-remote-control.jpg.webp",
            },
            {
                title: "수상 경력에 빛나는 화장실 디자인",
                text: "ViClean을 사용하면 모든 기능이 우아하게 내부에 숨겨져 있어 양변기가 슬림한 디자인을 유지하며 다른 모든 Villeroy & Boch 양변기와 완전히 일치합니다.",
                subtitle: "교묘하게 숨겨진 기술",
                image: "viclean-shower-toilet-in-modern-bathroom.webp",
            },
            {
                title: "완전 자동으로 최적의 위생",
                text: "위생에 있어서 ViClean은 샤워 노즐의 자동 청소와 먼지 방지 CeramicPlus 코팅을 통해 최고 수준의 기준을 제시합니다.",
                subtitle: "박테리아와 먼지가 없습니다",
                image: "viclean-wc-white-nozzle-and-dry-function.webp",
            },
            {
                title: "웨이브 모션 화장실 샤워",
                text: "독점적인 HarmonicWave 기술 덕분에 샤워 제트가 측면 웨이브 운동으로 앞뒤로 부드럽게 움직여 특히 쾌적하고 상쾌한 청소가 가능합니다.",
                subtitle: "ViClean 독점",
                image: "viclean-toilette-rimless-white.webp",
            },
        ],
        table: [
            {
                text: "샤워 기능",
                children: [
                    {
                        text: "엉덩이 샤워",
                        one: "3가지 세정 방식",
                        two: "3가지 세정 방식",
                    },
                    {
                        text: "레이디 샤워",
                        one: "3가지 세정 방식",
                        two: "3가지 세정 방식",
                    },
                    {
                        text: "컴포트 샤워",
                        one: "부드러운 물결",
                        two: "부드러운 물결",
                    },
                    {
                        text: "온도 설정",
                        one: "5개 레벨",
                        two: "5개 레벨",
                    },
                ],
            },
            {
                text: "위생",
                children: [
                    {
                        text: "샤워 노즐의 자동 사전 및 사후 청소",
                        check: true,
                        one: true,
                        two: true,
                    },
                    {
                        text: "다이렉트플러시 기술",
                        check: true,
                        one: true,
                        two: true,
                    },
                    {
                        text: "세라믹플러스 표면",
                        check: true,
                        one: true,
                        two: true,
                    },
                    {
                        text: "퀵 릴리스 메커니즘",
                        check: true,
                        one: true,
                        two: false,
                    },
                    {
                        text: "InvisibleJet 기술",
                        check: true,
                        one: true,
                        two: true,
                    },
                    {
                        text: "듀로플라스트 변기 시트",
                        check: true,
                        one: true,
                        two: true,
                    },
                ],
            },
            {
                text: "편의",
                children: [
                    {
                        text: "ComfortDry 온풍 건조기",
                        check: true,
                        one: false,
                        two: true,
                    },
                    {
                        text: "열선시트",
                        check: true,
                        one: false,
                        two: true,
                    },
                    {
                        text: "사용량 감지",
                        check: true,
                        one: false,
                        two: true,
                    },
                    {
                        text: "소프트 클로징 기능",
                        check: true,
                        one: true,
                        two: true,
                    },
                    {
                        text: "개별적으로 조정 가능한 사용자 프로필",
                        check: true,
                        one: true,
                        two: true,
                    },
                    {
                        text: "원격 제어",
                        check: true,
                        one: true,
                        two: true,
                    },
                    {
                        text: "바이클린 앱",
                        check: true,
                        one: true,
                        two: true,
                    },
                ],
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}

@media (min-width: 768px) {
}
</style>