<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/innovation/products/visual.jpg" class="visual-image">
                    <div class="vertical-title">Innovative products</div>
                </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Innovative strength since 1748</div>
                    <p class="txt txt--xs line-height-16">270년 이상 혁신은 Villeroy &amp; Boch의 기업 문화의 일부였습니다. 혁신은 긍정적인 이미지를 구축하고 125개국 이상에서 브랜드의 성공을 이끄는 데 도움이 되었습니다. 고품질 디자인과 더불어 혁신의 기본 목표는 항상 고객을 위한 기능성과 부가가치입니다. Villeroy &amp; Boch의 주요 혁신을 여기에서 확인할 수 있습니다.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--left">
                            <div class="quote">
                                <p class="quote__text">
                                    <v-img src="/images/company/innovation/products/quote-before.svg" class="quote__mark" />
                                    창의적 문화를 회사의 최고 경영진 차원에서 확립하고 중요한 우선 과제로 삼는 것이 매우 중요합니다.
                                    <v-img src="/images/company/innovation/products/quote-after.svg" class="quote__mark" />
                                </p>
                                <span class="quote__from">피터 델윙 박사 (Innovation Manager)</span>
                            </div>

                            <v-img src="/images/company/innovation/products/image1.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">DirectFlush 변기 - 지능형 엔지니어링</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                Villeroy &amp; Boch DirectFlush 변기는 기존의 림 대신 지능형 플러싱 시스템을 갖추고 있습니다. 변기 윗부분의 스플래시 가드와 물 분사구의 더 높은 위치 덕분에 변기 내부 표면 전체를 튀기지 않고 헹굽니다. DirectFlush는 작동당 3~4.5리터의 물만 소모하므로 최대 플러싱 효율을 보장합니다. DirectFlush 모델은 빠르고 쉽게 세척할 수 있도록 완전히 유약 처리되어 있습니다. 2015년 DirectFlush와 HygieneFlush 변기 판매량은 전년 대비 두 배로 증가했습니다.
                            </p>

                            <v-img src="/images/company/innovation/products/image2.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">TitanCeram - 미래를 가진 소재</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                TitanCeram은 260년 이상의 세라믹 생산 경험을 모아 고품질 프리미엄 디자인을 위해 특별히 개발된 혁신적인 소재입니다. 새로운 세라믹 소재는 특별히 조정된 생산 단계를 거쳐 고유하고 정밀한 모양의 제품을 만들 수 있습니다. TitanCeram은 매우 얇은 벽과 날카롭게 정의된 모서리가 특징인 참신하고 정밀한 모양인 Octagon pedestal washbasin과 같은 뛰어난 디자인 하이라이트를 의미합니다.
                            </p>
                        </v-sheet>
                    </v-col>

                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--right">
                            <v-img src="/images/company/innovation/products/image3.jpg" class="my-40px mt-xl-0 mb-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Manufacture Rock</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">Manufacture Rock 컬렉션은 수백만 년 전에 형성된 여러 겹의 암석으로 끊임없이 새로운 패턴, 구조 및 색상을 드러내는 슬레이트에서 영감을 받았습니다. 유약의 힌트와 함께 섬세한 부조로 재현된 단색의 검은색 프리미엄 도자기로 만든 식기의 생생한 슬레이트 표면은 매혹적으로 진짜 같은 느낌을 줍니다.</p>

                            <v-img src="/images/company/innovation/products/image4.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">NewWave - 영원한 모양</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">Villeroy &amp; Boch에 익숙한 사람이라면 누구나 NewWave를 알고 있을 것입니다. 2001년 출시 이후 NewWave는 Villeroy & Boch 제품군의 필수적인 부분이 되었습니다. 이 제품군을 특별하게 만드는 것: Villeroy &amp; Boch의 NewWave 시리즈는 전통적인 둥근 모양의 도자기에서 벗어나 독창적인 파도 효과를 가진 완전히 새로운 디자인을 만들어냈습니다. 2004년, 컵을 생산하는 데 사용된 특별한 디자인과 혁신적인 다이캐스팅 공정으로 손잡이가 전체 모양에 조화롭게 통합되어 Villeroy &amp; Boch는 NewWave 컬렉션에 대해 "Innovationspreis der deutschen Wirtschaft"[독일 경제 혁신상]를 수상했습니다.</p>

                            <v-img src="/images/company/innovation/products/image5.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">ViClean - 변기 문화의 새로운 차원</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">위생, 편안함, 지속 가능성 및 혁신적인 디자인은 Villeroy &amp; Boch의 샤워 변기의 설득력 있는 특징입니다. 샤워 변기는 아시아에서 변기 위생의 확립된 부분입니다. 이제 유럽 소비자에게 버튼 하나만 터치하면 부드럽고 개인화된 친밀한 케어의 이점을 확신시킬 때가 되었습니다. Villeroy &amp; Boch는 선도적인 전문가 및 유명 디자이너와 협력하여 ViClean 컨셉에서 변기와 비데의 기능성과 미학을 모두 결합하는 데 성공했습니다.</p>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "품질과 혁신",
                href: "/company/innovation/products",
            },
            {
                text: "창의적 제품",
                href: "/company/innovation/products",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            // > .v-image {
            //     margin-top: 40px;
            // }
        }
        &--right {
            // > .v-image {
            //     margin-top: 40px;
            //     margin-bottom: 40px;
            // }
        }
    }
}
.quote {
    text-align: center;
    &__text {
        font-family: var(--font-tertiary);
        font-style: italic;
        font-size: var(--tit-font-size-sm);
        line-height: 1.2;
        padding: 14px 0;
    }
    &__mark {
        display: inline-flex;
        transform: translateY(-4%);
        width: 34px;
    }
    &__from {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 700;
        color: #ddb57e;
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -90px;
                    bottom: -250px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 40px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                // > .v-image {
                //     margin-top: 80px;
                // }
            }
            &--right {
                transform: translateY(-160px);
                // > .v-image {
                //     margin-bottom: 80px;
                // }
            }
        }
    }
}

// Animations
@keyframes verticalLine {
    from {
        height: 0;
    }
    to {
        height: 120px;
    }
}
@keyframes verticalTitle {
    from {
        transform: translateY(-40px) rotate(90deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
    }
}
</style>