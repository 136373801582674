var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('collections-visual', {
    attrs: {
      "image": `${_vm.path}/visual.webp`,
      "title": "Collaro",
      "breadcrumbs": _vm.breadcrumbs
    }
  }), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("오늘날 현대적인 디자인은 단지 멋진 외모 그 이상입니다. 오히려 Collaro는 기분이 좋아지는 시대를 초월한 아름다운 생활 공간을 만듭니다. 스타일을 정의하고 선조로 장식하면서도 집처럼 편안합니다. 눈에 띄는 것은 컬렉션의 독특한 룩을 특징짓는 대비되는 디자인 엣지입니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("캐릭터와 스타일의 만남")])])], 1)], 1), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/finion-mirror-in-modern-white-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("시대를 초월한 세련된 욕실 컬렉션")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("필리그리 효과와 편안함이 만났습니다! 독특한 형태, 고품질 소재, 실용적인 기능성이 결합된 욕실을 경험해 보세요.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("Collaro 제품군을 살펴보세요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("다양한 색상, 모양, 크기와 결합된 다양한 세면대, 가구, 변기, 욕조를 통해 나만의 기분 좋은 오아시스를 창조할 수 있는 창의적인 자유를 누릴 수 있습니다.")])])], 1)], 1), _c('idea-product-list', {
    attrs: {
      "path": _vm.path,
      "items": _vm.products
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("영감을 주는 미학과 다양성")])])]), _c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.options
    }
  }, [_vm._l(_vm.images, function (image) {
    return [_c('swiper-slide', {
      key: image
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "img": `${_vm.path}/${image}`
      }
    }, [_c('v-responsive', {
      staticClass: "d-md-none",
      attrs: {
        "aspect-ratio": 4 / 3
      }
    }), _c('v-responsive', {
      staticClass: "d-none d-md-block",
      attrs: {
        "aspect-ratio": 5 / 2
      }
    })], 1)], 1)];
  }), _c('div', {
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('div', {
    staticClass: "swiper-button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 2)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("이것은 당신의 Collaro 욕실에 잘 어울립니다")])]), _c('v-row', {
    staticClass: "row--xxl mx-30px my-md-n52px mx-md-52px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-md-52px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/liberty-tap-chrom-on-collaro-washbasin-white.webp`
    }
  }), _c('v-card-text', [_c('div', {
    staticClass: "tit tit--sm mb-6px mb-md-8px"
  }, [_vm._v("올바른 피팅")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("리버티 피팅의 모던한 디자인과 콜라로의 독특한 곡선과 모서리가 조화로운 팀을 이루며 욕실을 아늑한 집으로 바꿔줍니다.")]), _c('div', {
    staticClass: "mt-12px mt-md-16px d-flex align-center"
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("Liberty 피팅 살펴보기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)])], 1)], 1), _c('v-col', {
    staticClass: "pa-md-52px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/universo-twistflush-bathroom-white-toilet-on-blue-green-background+(1).webp`
    }
  }), _c('v-card-text', [_c('div', {
    staticClass: "tit tit--sm mb-6px mb-md-8px"
  }, [_vm._v("TwistFlush를 갖춘 변기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Universo 양변기는 순수한 소용돌이력과 Collaro와 완벽하게 어울리는 순수한 디자인을 결합했습니다. 혁신적인 TwistFlush 세척 기술 덕분에 특히 철저하게 세척하고 물을 절약합니다.")]), _c('div', {
    staticClass: "mt-12px mt-md-16px d-flex align-center"
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("Universo TwistFlush를 만나보세요")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }