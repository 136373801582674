var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('collections-visual', {
    attrs: {
      "image": `${_vm.path}/visual.webp`,
      "title": "Loop & Friends",
      "breadcrumbs": _vm.breadcrumbs
    }
  }), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("눈을 감고 가장 좋아하는 장소를 상상해 보세요. 방랑벽을 느끼시나요? 그렇다면 그것이 당신에게 영감을 주고 꿈의 목적지를 당신의 욕실로 가져오도록 하세요! 환상적으로 들리나요? 루프앤프렌즈 입니다. Villeroy & Boch의 다용도 욕실 컬렉션을 사용하면 욕실을 매우 개인적인 웰빙의 오아시스로 변화시킬 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("Loop & Friends 제품군을 살펴보세요")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("눈을 감고 가장 좋아하는 장소를 상상해 보세요. 방랑벽을 느끼시나요? 그렇다면 그것이 당신에게 영감을 주고 꿈의 목적지를 당신의 욕실로 가져오도록 하세요! 환상적으로 들리나요? 루프앤프렌즈 입니다. Villeroy & Boch의 다용도 욕실 컬렉션을 사용하면 욕실을 매우 개인적인 웰빙의 오아시스로 변화시킬 수 있습니다.")])])], 1)], 1), _c('idea-product-list', {
    attrs: {
      "path": _vm.path,
      "items": _vm.products
    }
  })], 1)], 1), _c('page-section', [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-and-friends-washbasin-in-white-bathroom-santorinian-style.webp`
    }
  })], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("모든 Loop & Friends 제품")])]), _c('v-tabs', {
    staticClass: "mb-20px mb-md-40px",
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.tabs, function (item) {
    return [_c('v-tab', {
      key: item.tab
    }, [_c('v-btn', {
      staticClass: "rounded-xs",
      attrs: {
        "large": ""
      }
    }, [_vm._v(" " + _vm._s(item.tab) + " ")])], 1)];
  })], 2), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.products, function (item) {
    return [_c('v-tab-item', {
      key: item.tab
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "6",
        "sm": "4",
        "lg": "3"
      }
    }, [_c('shop-product-card')], 1)], 1), _c('pagination-component', {
      attrs: {
        "value": _vm.page,
        "count": _vm.pageCount
      },
      on: {
        "input": function (page) {
          return _vm.$router.push({
            query: Object.assign({}, _vm.$route.query, {
              page
            })
          });
        }
      }
    })], 1)];
  })], 2)], 1), _c('div', {
    staticClass: "d-flex justify-center mt-24px mt-md-32px"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "white",
      "elevation": "4"
    }
  }, [_vm._v(" 모든 Loop & Friends 상품 보러가기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit text-center"
  }, [_vm._v("하나의 제품 - 다른 단계")])]), _c('center-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.steps
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }