var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "src": "/images/company/history/tradition/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("A premium brand with tradition")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("다른 브랜드와는 달리 Villeroy & Boch는 유럽 문화에 깊이 뿌리를 두고 있습니다. 그리고 위대한 브랜드만이 할 수 있는 방식으로, 시대와 함께 움직이면서도 정체성을 보존하는 방법을 알고 있습니다.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('div', {
    staticClass: "px-0px px-xl-50px"
  }, [_c('iframe', {
    staticClass: "youtube",
    attrs: {
      "src": "https://www.youtube.com/embed/mxF7NOg4Mv8?si=jP6X1jTQWg0PERzV",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mt-40px mb-20px mt-xl-80px"
  }, [_vm._v("독일 왕조: 빌레로이&보흐")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v("빌레루아 & 보흐의 역사에 대한 이 영화는 200년 전에 독일과 프랑스 가문이 어떻게 함께 모여 가장 성공적인 왕조 중 하나를 형성했는지 설명합니다. 개척 정신과 선견지명: 인터뷰에서 프랑수아 보흐와 니콜라스 빌레루아의 후손들이 두 개의 작은 도자기 공장에서 시작된 회사에 대해 이야기합니다.")]), _c('p', [_vm._v("그리고 글로벌 기업으로 성장했습니다.")]), _c('p', [_vm._v(" 영상 정보 (독일어) "), _c('br'), _vm._v(" \"Deutsche Dynastien\" [독일 왕조]: Villeroy & Boch (43분 / 독일, 프랑스, 미국 2015 / WDR / neue artfilm 제작) ")])])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }