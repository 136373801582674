<template>
    <client-page>
        <collections-visual :image="`${path}/visual.webp`" title="Hommage" :breadcrumbs="breadcrumbs" />

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm text-center">절제된 럭셔리와 스타일이 삶의 기쁨을 만나는 오마주에 대한 오마주입니다. 20세기 초반에서 영감을 받은 이 제품은 시대를 초월한 위대한 시대의 디자인 요소를 결합합니다. 실제 목재 베니어판은 대리석과 완벽하게 조화를 이루고 고급스러운 계단 부조로 컬렉션의 확실한 스타일을 드러냅니다. 진정한 스타일은 시대를 초월하기 때문이죠.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <div class="tit">클래식의 모습</div>
                </div>
            </v-container>
            <div class="tit-wrap tit-wrap--lg">
                <v-img :src="`${path}/hommage-washbasins-black-with-mirrors-oval-and-bathtub-freestanding.webp`" />
            </div>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">오마주 제품군을 만나보세요</div>
                        <p class="txt txt--sm">Homage 컬렉션은 인상적인 직선이 특징입니다. 모든 세라믹의 계단 부조는 물론 고급 액센트와 욕조의 래커 처리된 실제 나무 발과 같은 고품질 디테일을 통해 Homage는 높은 품질로 매료됩니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <idea-product-list :path="path" :items="products" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--sm text-center">
                    <div class="tit">위대한 시대의 고전을 경험해보세요</div>
                </div>
                <center-slide :path="path" :items="images"></center-slide>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="text-center">
                    <div class="tit mb-12px mb-md-16px">큰 입구에 대한 준비가 되셨습니까?</div>
                    <p class="txt txt--sm">은은한 블랙 새틴 프레임으로 처음부터 반짝반짝 빛나는 방에서 저녁을 준비하세요. 무광 블랙 표면과 고품질 디테일이 우아하고 절제된 표현으로 Homage의 눈에 띄는 형태를 완성합니다.</p>
                </div>
                <three-images :path="path" first="hommage-bathroom-with-woman-leaning-against-bathtub-washbasin-black.webp" second="pic_7101A1R1_921988_TVW12600200061.png" third="hommage-washbasin-black-with-man-straightens-fly-in-front-of-mirror.webp" text="창립 275주년을 맞아 클래식한 스타일을 개성 넘치는 퓨어 블랙으로 재해석했습니다. 욕실을 나만의 휴식 공간으로 바꿔주는 매트 컬러입니다. 패션은 왔다가 갑니다. 경의가 남아 있습니다." />
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CollectionsVisual from "@/components/client/collections/collections-visual.vue";
import IdeaProductList from "@/components/client/ideas/idea-product-list.vue";
import CenterSlide from "@/components/client/dumb/center-slide.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        CollectionsVisual,
        IdeaProductList,
        CenterSlide,
        ThreeImages,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            centeredSlides: false,
            speed: 500,
            loop: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
        },
        breadcrumbs: [
            {
                text: "컬렉션",
                href: "/collections/antao",
            },
            {
                text: "Hommage",
                href: "/collections/Hommage",
            },
        ],
        path: "/images/collections/hommage",
        products: [
            {
                title: "욕조",
                text: "독립형 욕조는 물의 열을 빠르게 흡수하는 고품질 Quaryl®로 제작되었습니다. 욕실에서 럭셔리함을 표현해보세요.",
                image: "hommage-bathtub-black-white-and-freestanding.webp",
                cols: "12",
                button: "Hommage 욕조",
                link: "/",
            },
            {
                title: "세면기",
                text: "고급스러운 계단 부조는 오마주에 선명함과 화려함을 동시에 선사합니다.",
                image: "hommage-washbasin-black-with-tap-black.webp",
                cols: "6",
                button: "Hommage 세면기",
                link: "/",
            },
            {
                title: "양변기",
                text: "시대를 초월하면서도 인상적인 디자인 언어 덕분에 Homage 변기는 욕실에서 눈길을 사로잡습니다.",
                image: "Hommage-toilet-white-with-wood-seat-and-bidet-white.webp",
                cols: "6",
                button: "Hommage 양변기",
                link: "/",
            },
            {
                title: "가구",
                text: "짙은 색의 단풍나무로 만든 고품질 천연목 베니어 가구와 카라라 대리석이 이 컬렉션의 욕실 가구 스타일을 더욱 돋보이게 합니다.",
                image: "hommage-washbasin-with-two-side-cabinets-with-glass-doors-and-bathtube-white.webp",
                cols: "12",
                button: "Hommage 욕실가구",
                link: "/",
            },
        ],
        images: [
            "hommage-washbasin-white-bathtub-white-and-bathroom-furniture-brown.webp",
            "hommage-freestanding-bathtub-and-pedestal-washbasin-in-black.webp",
            "hommage-bathtub-white-freestanding-washbasins-white-with-mirrors-and-shower-tray.webp",
            //
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}

@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
}
</style>