var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "order-md": "2",
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-sheet', {
    attrs: {
      "max-width": "700",
      "color": "transparent"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "d-block",
      attrs: {
        "src": `${_vm.path}/${item}`,
        "alt": ""
      }
    })])];
  })], 2), _c('div', {
    staticClass: "swiper-pagination"
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order-md": "1",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--prev",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order": "3",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--next",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }