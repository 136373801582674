<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img max-width="798" src="/images/company/brands/visual.jpg" class="visual-image" />
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">All Villeroy&amp;Boch brands</div>
                    <p class="txt txt--xs line-height-16">Villeroy & Boch AG는 125개국 이상에 진출해 있습니다. 전 세계 고객에게 프리미엄 품질과 포괄적인 제품 포트폴리오를 제공하고,빌레로이앤보흐브랜드, 우리의 범위에는 사람들의 생활 환경을 개선하도록 설계된 다른 성공적인 브랜드가 포함됩니다. 이러한 지역 제품 브랜드는 또한 그룹의 국제화의 기둥입니다.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row class="row--xl">
                    <v-col cols="12" lg="6">
                        <v-row class="row--lg">
                            <template v-for="item in left">
                                <v-col cols="12" :key="item">
                                    <v-card tile flat min-height="268" class="brand-card" :href="item.link" target="_blank">
                                        <v-card tile flat outlined width="208" class="brand-card__image">
                                            <v-responsive :aspect-ratio="1 / 1">
                                                <v-img :src="item.image" />
                                            </v-responsive>
                                        </v-card>
                                        <div>
                                            <div class="tit tit--xs line-hegiht-11 font-weight-bold font-tertiary">
                                                {{ item.title }}
                                            </div>
                                            <div class="font-size-12 tertiary--text mt-10px mb-20px">
                                                {{ item.subtitle }}
                                            </div>
                                            <p class="txt txt--xs">
                                                {{ item.text }}
                                            </p>
                                            <div class="brand-card__find">더 알아보기</div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-row class="row--lg pt-lg-52px">
                            <template v-for="item in right">
                                <v-col cols="12" :key="item">
                                    <v-card tile flat min-height="268" class="brand-card" :href="item.link" target="_blank">
                                        <v-card tile flat outlined width="208" class="brand-card__image">
                                            <v-responsive :aspect-ratio="1 / 1">
                                                <v-img :src="item.image" />
                                            </v-responsive>
                                        </v-card>
                                        <div>
                                            <div class="tit tit--xs line-hegiht-11 font-weight-bold font-tertiary">
                                                {{ item.title }}
                                            </div>

                                            <template v-if="item.subtitle">
                                                <div class="font-size-12 tertiary--text mt-10px mb-20px">
                                                    {{ item.subtitle }}
                                                </div>
                                            </template>

                                            <template v-if="item.text">
                                                <p class="txt txt--xs">
                                                    {{ item.text }}
                                                </p>
                                            </template>

                                            <div class="brand-card__find">더 알아보기</div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "회사",
                href: "/company/about/figures",
            },
            {
                text: "관련 브랜드",
                href: "/company/brands",
            },
        ],
        left: [
            {
                image: "/images/company/brands/brand-1.jpg",
                title: "Alföldi",
                subtitle: "Bathroom quality for over 50 years",
                text: "헝가리 시장을 위한 위생용품은 Alföldi 브랜드로 생산됩니다. 헝가리의 Hódmezővásárhely 마을에 있는 이 부지는 1992년부터 Villeroy & Boch AG가 소유하고 있습니다.",
                link: "https://www.alfoldi.hu/",
            },
            {
                image: "/images/company/brands/brand-2.jpg",
                title: "Gustavsberg",
                subtitle: "QUALITY OF LIFE SINCE 1825",
                text: "북유럽 시장 전체와 발트 3국에서 Gustavsberg 브랜드는 기능적이고 스칸디나비아적인 디자인의 욕실 제품을 대표합니다. 2000년부터 스웨덴의 Gustavsberg 사이트는 주식회사의 일부인 Villeroy & Boch Gustavsberg AB가 되었습니다.",
                link: "https://www.gustavsberg.com/en/",
            },
            {
                image: "/images/company/brands/brand-3.jpg",
                title: "Nahm Sanitaryware",
                subtitle: "Signature of individual",
                text: "사라부리에 본사를 둔 태국 브랜드 Nahm Sanitaryware는 아시아 시장을 대상으로 위생 제품을 생산하고 있으며, 2008년부터 Villeroy & Boch AG가 소유하고 있습니다.",
                link: "https://www.nahm.co.th/",
            },
            {
                image: "/images/company/brands/brand-4.jpg",
                title: "Vatette",
                subtitle: "svensk kvalité",
                text: "수전, 피팅 및 위생 설비 액세서리는 Vatette 브랜드로 생산됩니다. 스웨덴 Vårgårda 마을에 있는 이 사이트는 Villeroy & Boch Gustavsberg AB가 소유하고 있습니다.",
                link: "https://www.vatette.se/",
            },
            {
                image: "/images/company/brands/brand-5.jpg",
                title: "vivo",
                subtitle: "vivo - live the moment!",
                text: "이 제품은 복잡하지 않고 진짜입니다. 더 이상 단순히 생활하기 위한 것이 아니라 경험하기 위한 것이 되는 집에 완벽합니다. vivo는 특별한 순간을 위한 일상 사용을 위해 디자인되었습니다.",
                link: "https://vivo-villeroy-boch-group.com/en-GB/",
            },
            {
                image: "/images/company/brands/brand-6.jpg",
                title: "Armitage Shanks",
                link: "https://www.armitageshanks-mena.com/",
            },
        ],
        right: [
            {
                image: "/images/company/brands/brand-7.jpg",
                title: "Signature",
                subtitle: "Graceful",
                text: "매력적이고, 우아하고, 조화롭다는 뜻입니다. 정통적입니다. 이러한 속성은 Anmut 식기 시리즈를 적절하게 설명합니다. 고급 흰색 프리미엄 본 포슬린으로 만들어졌으며, 축소되고 투명한 미학으로 사로잡힙니다. 이 고전적인 아름다움에 매료되세요.",
                link: "https://www.villeroy-boch.de/shop/signature_start.html?lang=de_DE&_gl=1*mznjmw*_ga*MTkwODQ0MjU5Mi4xNjEwMTA4MjAw*_ga_WHRER6BN8Y*MTYzNDYzMDg5NS43MC4xLjE2MzQ2MzMwMDYuNTk.&_ga=2.129697996.1169001084.1634630896-1908442592.1610108200",
            },
            {
                image: "/images/company/brands/brand-8.jpg",
                title: "Mondial",
                subtitle: "Clever solutions, intelligent products",
                text: "루마니아 시장을 위한 위생 도자기는 Mondial 브랜드로 생산됩니다. 루마니아 루고이에 있는 이 사이트는 1996년부터 Villeroy & Boch AG의 일부가 되었습니다.",
                link: "http://mondiallugoj.ro/",
            },
            {
                image: "/images/company/brands/brand-9.jpg",
                title: "Sanipa",
                subtitle: 'Bathroom furniture "made in Germany"',
                text: "남부 독일의 트레히틀링겐에 본사를 둔 제조업체 Sanipa는 1976년에 설립되었으며 2008년부터 Villeroy & Boch AG의 전액 출자 자회사가 되었습니다. 이 위생 회사의 제품 포트폴리오에는 욕실 가구, 거울, 세면기 및 조명 요소가 포함됩니다.",
                link: "https://www.sanipa.de/home/",
            },
            {
                image: "/images/company/brands/brand-10.jpg",
                title: "Like.",
                subtitle: "unconventional and fresh",
                text: "특별한 like.-컬렉션은 독특하고 신선한 라인으로 당신을 사로잡을 것입니다. 이것은 실험을 좋아하고 새로운 것을 더 자주 시도하는 모든 사람들에게 딱 맞는 것입니다.",
                link: "https://www.villeroy-boch.de/shop/like.html?lang=de_DE",
            },
            {
                image: "/images/company/brands/brand-11.jpg",
                title: "Ideal Standard",
                link: "https://www.idealstandard.co.uk/",
            },
            {
                image: "/images/company/brands/brand-12.jpg",
                title: "Porcher",
                link: "https://www.porcher.com/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        .container {
            position: relative;
        }
        &__sheet {
            max-width: 90%;
            padding: 30px;
            margin: -220px auto 0;
            position: relative;
            z-index: 1;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
    }
    &--1 {
        padding-top: 80px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            > .v-image {
                margin-top: 40px;
            }
        }
        &--right {
            > .v-image {
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
    }
}
.brand-card {
    position: relative;
    margin-left: 104px;
    padding: 30px;
    padding-left: 134px;
    display: flex;
    align-items: center;
    border-bottom: 6px solid var(--v-primary-base);
    &__image {
        position: absolute;
        left: -104px;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        .v-image {
            transition: all 0.5s;
        }
    }
    &__find {
        margin-top: 14px;
        font-size: var(--txt-font-size-sm);
        color: var(--v-primary-base);
        position: relative;
        padding-left: 30px;
        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 1px;
            background: currentColor;
            left: 0;
            top: 54%;
        }
    }
    &:hover {
        .brand-card {
            &__image {
                .v-image {
                    transform: scale(1.1);
                }
            }
            &__find {
                animation: findOutMore 0.5s forwards ease-in-out;
                &::before {
                    animation: findOutMoreBefore 0.5s forwards ease-in-out;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin: 0;
                position: absolute;
                top: 50%;
                right: var(--container-gutter);
                transform: translateY(-50%);
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                }
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--1 {
            padding-top: 120px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                > .v-image {
                    margin-top: 80px;
                }
            }
            &--right {
                > .v-image {
                    margin-bottom: 80px;
                }
            }
        }
    }
}

// Animations
@keyframes findOutMore {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes findOutMoreBefore {
    from {
        width: 0;
    }
    to {
        width: 20px;
    }
}
</style>