var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "max-width": "798",
      "src": "/images/company/brands/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("All Villeroy&Boch brands")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("Villeroy & Boch AG는 125개국 이상에 진출해 있습니다. 전 세계 고객에게 프리미엄 품질과 포괄적인 제품 포트폴리오를 제공하고,빌레로이앤보흐브랜드, 우리의 범위에는 사람들의 생활 환경을 개선하도록 설계된 다른 성공적인 브랜드가 포함됩니다. 이러한 지역 제품 브랜드는 또한 그룹의 국제화의 기둥입니다.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.left, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "brand-card",
      attrs: {
        "tile": "",
        "flat": "",
        "min-height": "268",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-card', {
      staticClass: "brand-card__image",
      attrs: {
        "tile": "",
        "flat": "",
        "outlined": "",
        "width": "208"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image
      }
    })], 1)], 1), _c('div', [_c('div', {
      staticClass: "tit tit--xs line-hegiht-11 font-weight-bold font-tertiary"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "font-size-12 tertiary--text mt-10px mb-20px"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")]), _c('p', {
      staticClass: "txt txt--xs"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "brand-card__find"
    }, [_vm._v("더 알아보기")])])], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--lg pt-lg-52px"
  }, [_vm._l(_vm.right, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "brand-card",
      attrs: {
        "tile": "",
        "flat": "",
        "min-height": "268",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-card', {
      staticClass: "brand-card__image",
      attrs: {
        "tile": "",
        "flat": "",
        "outlined": "",
        "width": "208"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image
      }
    })], 1)], 1), _c('div', [_c('div', {
      staticClass: "tit tit--xs line-hegiht-11 font-weight-bold font-tertiary"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), item.subtitle ? [_c('div', {
      staticClass: "font-size-12 tertiary--text mt-10px mb-20px"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")])] : _vm._e(), item.text ? [_c('p', {
      staticClass: "txt txt--xs"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])] : _vm._e(), _c('div', {
      staticClass: "brand-card__find"
    }, [_vm._v("더 알아보기")])], 2)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }