<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/history/tradition/visual.jpg" class="visual-image"> </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">A premium brand with tradition</div>
                    <p class="txt txt--xs line-height-16">다른 브랜드와는 달리 Villeroy & Boch는 유럽 문화에 깊이 뿌리를 두고 있습니다. 그리고 위대한 브랜드만이 할 수 있는 방식으로, 시대와 함께 움직이면서도 정체성을 보존하는 방법을 알고 있습니다.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <div class="px-0px px-xl-50px">
                    <iframe src="https://www.youtube.com/embed/mxF7NOg4Mv8?si=jP6X1jTQWg0PERzV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="youtube" />

                    <div class="tit tit--xs font-tertiary mt-40px mb-20px mt-xl-80px">독일 왕조: 빌레로이&보흐</div>
                    <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                        <p>빌레루아 & 보흐의 역사에 대한 이 영화는 200년 전에 독일과 프랑스 가문이 어떻게 함께 모여 가장 성공적인 왕조 중 하나를 형성했는지 설명합니다. 개척 정신과 선견지명: 인터뷰에서 프랑수아 보흐와 니콜라스 빌레루아의 후손들이 두 개의 작은 도자기 공장에서 시작된 회사에 대해 이야기합니다.</p>
                        <p>그리고 글로벌 기업으로 성장했습니다.</p>
                        <p>
                            영상 정보 (독일어) <br />
                            "Deutsche Dynastien" [독일 왕조]: Villeroy &amp; Boch (43분 / 독일, 프랑스, 미국 2015 / WDR / neue artfilm 제작)
                        </p>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "회사",
                href: "/company/about/figures",
            },
            {
                text: "역사와 전통",
                href: "/company/history/history",
            },
            {
                text: "전통",
                href: "/company/history/tradition",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
        }
    }
    &--1 {
        padding-top: 40px;
    }
}
.youtube {
    width: 100%;
    aspect-ratio: 16/9;
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
            }
        }
        &--1 {
            padding-top: 80px;
        }
    }
}
</style>