var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "src": "/images/company/innovation/digital/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("Driving the digital revolution")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("욕실 및 웰빙과 식기 부문의 경우, 디지털 혁명은 기회를 의미하며, Villeroy & Boch가 이러한 새로운 지평을 어떻게 탐색하고 있는지는 선택된 사례를 통해 알 수 있습니다.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-sheet', {
    staticClass: "sub-sheet px-xl-50px",
    attrs: {
      "color": "transparent"
    }
  }, [_c('iframe', {
    staticClass: "youtube mb-40px mb-xl-80px",
    attrs: {
      "src": "https://www.youtube.com/embed/jrZ0dnEozVg?si=waqOlvOqVZ73BXNC",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }), _c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("욕실 계획 2.0")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v(" 몇 번만 클릭하면 새로운 욕실이 준비됩니다. 최소한 Villeroy & Boch의 가상 욕실이라면 말입니다.온라인 욕실 플래너이 도구를 사용하면 잠재 고객이 자신의 컴퓨터나 태블릿을 사용하여 새 욕실의 대략적인 윤곽을 쉽게 디자인할 수 있습니다. ")]), _c('p', [_vm._v(" 사용자는 몇 단계만 거치면 계획 과정을 안내받습니다. 욕실 평면도를 입력하고, 문과 창문을 추가한 다음, 세면기와 변기, 비데와 소변기, 욕조, 샤워기, 현재 범위의 욕실 가구와 거울을 배치하면 끝입니다! 3차원 뷰와 정확한 치수가 포함된 평면도는 완성된 욕실에 대한 첫인상을 얻는 좋은 방법입니다. Villeroy & Boch는 고객에게 무료 가상 현실 골판지를 보내 집에서 욕실 평면도를 3D로 쉽게 볼 수 있도록 합니다. 욕실 평면도를 완성하면 사용자에게 3D 뷰 링크가 포함된 이메일이 전송됩니다. 고객이 가상 현실 골판지를 받으면 링크를 클릭하고 스마트폰을 가상 현실 골판지에 넣기만 하면 됩니다. 완벽한 3차원 시각화를 통해 욕실이 어떻게 보일지 쉽게 상상할 수 있으며 계획의 보안을 제공합니다. ")])]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/digital/image1.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("소셜 미디어 @ Villeroy&Boch")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v(" 전 세계적으로 50억 명 이상의 사람들이 온라인에 접속하고 있으며, 그 중 절반 이상이 Facebook과 YouTube를 사용하고 있으며, 3명 중 1명이 Instagram에서 활동하고 있습니다. 소셜 미디어 채널은 붐을 일으키고 있으며, 기업, 브랜드, 제품을 소개하기에 훌륭한 플랫폼을 제공합니다. Villeroy & Boch의 경우 소셜 웹은 젊은 국제 사용자를 포함한 타겟 그룹에 도달하고 상호 작용을 장려하는 주요 방법 중 하나입니다. Facebook에서 Instagram, YouTube, Twitter, Pinterest, WeChat까지: Villeroy & Boch는 세계에서 가장 널리 사용되는 소셜 미디어 채널에 자체적으로 존재하며 대규모 국제 팬 커뮤니티에 적극적으로 접근합니다. ")]), _c('p', [_vm._v("소규모의 사내 소셜 미디어 콘텐츠 팩토리에서 각 채널에 적합한 동영상 콘텐츠, 이미지, 스토리가 매일 다양한 소셜 미디어 플랫폼에 제작됩니다.")])]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/digital/image2.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("네트워크화된 사고: Villeroy&Boch의 크로스 채널")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v(" Dining & Lifestyle Division에서 개발한 혁신적인 아이디어는 새로운 제품군, 장식 또는 세라믹 모양에 국한되지 않고 지능형 고객 서비스로 확장됩니다. \"크로스 채널\"이 지침 원칙입니다. 요즘 회사는 온라인과 오프라인 모두에서 고객과 함께해야 합니다. Villeroy & Boch 매장에서 개인적인 조언이 여전히 매우 중요하지만 디지털 서비스가 점점 더 필수적이 되고 있습니다. Personal Sales Assistant(PSA), 알림 서비스가 포함된 위시리스트 및 Check & Reserve 또는 Click & Collect와 같은 서비스를 통해 고객은 디지털 방식으로 픽업되어 편리하게 디지털 방식으로 쇼핑할 수 있습니다. 당사의 드롭시핑 서비스도 언급할 가치가 있습니다. 고객은 당사 매장에서 현지에서 쇼핑한 후 구매한 상품을 편리하게 집으로 배송받을 수 있습니다. ")]), _c('p', [_vm._v("수많은 다른 혜택 외에도 고객 프로그램인 My Club은 쇼핑 시 온라인과 오프라인에서 모두 사용할 수 있는 포인트를 모을 수 있는 기회를 제공합니다.")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--lg flex-column"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "sns-card",
      attrs: {
        "img": item.image,
        "href": item.link,
        "width": "274",
        "target": "_blank",
        "tile": "",
        "flat": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "sns-card__inner"
    }, [_c('div', [_c('div', {
      staticClass: "sns-card__sub-title"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "sns-card__title"
    }, [_vm._v(" Villeroy&Boch "), _c('br'), _vm._v(" on " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "sns-card__line"
    }), _c('v-btn', {
      staticClass: "sns-card__button rounded-0",
      attrs: {
        "outlined": "",
        "color": "white"
      }
    }, [_vm._v("자세히 보기")])], 1)])])], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }