var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('collections-visual', {
    attrs: {
      "image": `${_vm.path}/visual.webp`,
      "title": "Hommage",
      "breadcrumbs": _vm.breadcrumbs
    }
  }), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("절제된 럭셔리와 스타일이 삶의 기쁨을 만나는 오마주에 대한 오마주입니다. 20세기 초반에서 영감을 받은 이 제품은 시대를 초월한 위대한 시대의 디자인 요소를 결합합니다. 실제 목재 베니어판은 대리석과 완벽하게 조화를 이루고 고급스러운 계단 부조로 컬렉션의 확실한 스타일을 드러냅니다. 진정한 스타일은 시대를 초월하기 때문이죠.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("클래식의 모습")])])]), _c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/hommage-washbasins-black-with-mirrors-oval-and-bathtub-freestanding.webp`
    }
  })], 1), _c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("오마주 제품군을 만나보세요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Homage 컬렉션은 인상적인 직선이 특징입니다. 모든 세라믹의 계단 부조는 물론 고급 액센트와 욕조의 래커 처리된 실제 나무 발과 같은 고품질 디테일을 통해 Homage는 높은 품질로 매료됩니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('idea-product-list', {
    attrs: {
      "path": _vm.path,
      "items": _vm.products
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("위대한 시대의 고전을 경험해보세요")])]), _c('center-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.images
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("큰 입구에 대한 준비가 되셨습니까?")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("은은한 블랙 새틴 프레임으로 처음부터 반짝반짝 빛나는 방에서 저녁을 준비하세요. 무광 블랙 표면과 고품질 디테일이 우아하고 절제된 표현으로 Homage의 눈에 띄는 형태를 완성합니다.")])]), _c('three-images', {
    attrs: {
      "path": _vm.path,
      "first": "hommage-bathroom-with-woman-leaning-against-bathtub-washbasin-black.webp",
      "second": "pic_7101A1R1_921988_TVW12600200061.png",
      "third": "hommage-washbasin-black-with-man-straightens-fly-in-front-of-mirror.webp",
      "text": "창립 275주년을 맞아 클래식한 스타일을 개성 넘치는 퓨어 블랙으로 재해석했습니다. 욕실을 나만의 휴식 공간으로 바꿔주는 매트 컬러입니다. 패션은 왔다가 갑니다. 경의가 남아 있습니다."
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }