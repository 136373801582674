<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/innovation/awards/visual.jpg" class="visual-image">
                    <div class="vertical-title">Awards</div>
                </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Award-winning design competence</div>
                    <p class="txt txt--xs line-height-16">Villeroy &amp; Boch는 뛰어난 디자인과 기능성을 자랑합니다.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--left">
                            <div class="tit tit--xs font-tertiary mb-20px">Octagon</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                2015년 Octagon은 권위 있는 GOOD DESIGN™ 상을 수상했으며, 뉴욕의 Boutique Design 무역 박람회에서 욕실 부문의 "최우수 제품 디자인" 부문에서 최종 후보에 올랐습니다. 이 혁신적인 디자인은 2016년에도 디자인 전문가들을 다시 한번 설득했습니다. Octagon은 세계에서 가장 중요한 디자인 상 중 하나인 iF DESIGN AWARD를 수상했습니다. 이 특이한 세면기의 내부는 광택이 나는 팔각형 수정과 비슷합니다. 매우 정밀한 모서리, 각도 및 면은 Villeroy & Boch에서 개발한 신소재인 TitanCeram 덕분에 가능했습니다. 하지만 세면기가 유일한 특별한 특징은 아닙니다. 가죽이나 진짜 나무 또는 돌 베니어로 마감한 받침대 위에 위치하기 때문에 독립형 세면기는 욕실에서 진정한 디자인 하이라이트가 됩니다. 
                            </p>

                            <v-img src="/images/company/innovation/awards/image1.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Just Silence</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                Just Silence 야외 온수 욕조의 매력 중 하나는 단순함인데, 2015년 2월부터 수상을 했습니다. 50명의 디자인 전문가로 구성된 심사위원단이 iF 디자인 어워드를 위해 온수 욕조를 선정했습니다. 미니멀한 색상 구성과 다양한 누워 있는 위치 외에도 2.13m x 2.24m 풀은 혁신적인 좌석 컨셉으로 인상적입니다. Just Silence는 다리 공간을 희생하지 않고도 최대 5명이 서로 마주 앉을 수 있는 계단식 형태를 제공합니다!
                            </p>

                            <v-img src="/images/company/innovation/awards/image2.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">La Boule</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                La Boule는 Villeroy & Boch의 원래 강점인 세라믹 전문성과 멋진 디자인으로 돌아갔습니다. Helen von Boch는 1970년대에 "Sphere"로 디자인 역사를 썼습니다. Villeroy & Boch는 Sphere의 현대적이고 새로운 해석인 La Boule을 통해 1970년대의 스타일과 기능에서 벗어나고 있습니다. 2인용 식기 세트인 La Boule은 오늘날의 디자인 애호가들의 요구에 이상적으로 부합합니다. 이 제품은 두 개의 그릇, 두 개의 깊은 접시, 두 개의 평평한 접시, 단색 프리미엄 도자기로 된 서빙 접시로 구성되어 있습니다. 매트 블랙, 샤이니 화이트, 그리고 대조적인 흑백 버전으로 제공되는 La Boule은 Red Dot Design Award 심사위원단에 의해 "Winner 2020"으로 선정되었습니다. 
                            </p>

                            <v-img src="/images/company/innovation/awards/image3.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">MetroChic</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                Villeroy & Boch는 또 다른 유명한 Red Dot Design Award를 수상하게 되어 기쁩니다. MetroChic 컬렉션의 잔은 "Best of the Best" 부문에서 심사위원단을 감동시켰습니다. MetroChic은 도자기, 칼붙이, 선물이 포함된 시대를 초월한 우아한 Villeroy & Boch Signature 컬렉션입니다. 명확하고 독특한 디자인을 자랑하는 MetroChic Glass 잔은 2020년을 위해 개발되었으며 MetroChic의 우아함을 강조합니다.
                            </p>

                            <v-img src="/images/company/innovation/awards/image4.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">DirectFlush</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                DirectFlush 기술을 탑재한 Omnia Architectura 변기는 두 가지 상을 수상했습니다. 전문가 심사위원단이 선정한 2014년 유니버설 디자인 어워드와 소비자 패널이 선정한 2014년 유니버설 디자인 소비자 선호 제품으로 선정되었습니다. Villeroy & Boch는 혁신적인 DirectFlush 변기 기술로 위생 분야의 새로운 기준을 제시하고 있습니다. 림리스 디자인으로 빠르고 쉽게 세척할 수 있습니다. DirectFlush는 많은 방문객이 찾는 병원과 공공 건물, 그리고 개인 가정에서 오늘날의 높은 위생 기준을 충족하는 데 이상적입니다.
                            </p>
                        </v-sheet>
                    </v-col>

                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--right">
                            <v-img src="/images/company/innovation/awards/image5.jpg" class="my-40px mt-xl-0 mb-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Manufacture Collier</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                자연스러운 색상의 가정용 액세서리는 캐주얼한 우아함을 자랑하면서도 따뜻하고 흙빛 색조로 긍정적인 분위기를 조성합니다. Manufacture Collier 꽃병과 같은 독특한 제품으로 이러한 차분한 효과를 쉽게 얻을 수 있습니다. 2018년 Red Dot Award를 수상한 Manufacture Collier는 베이지와 토프의 새로운 부드러운 색상으로 트렌드를 선도하고 있으며 자연스러운 색상의 우아함으로 현대 생활 공간의 미니멀한 모습을 강조합니다. 
                            </p>

                            <v-img src="/images/company/innovation/awards/image6.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">it's my match</div>
                            <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                <p>2020년의 수상자는 "it's my match" 트렌드 컬렉션으로, 2019년에 독일 디자인 어워드, 레드닷 디자인 어워드, 키친이노베이션 어워드를 수상했습니다.</p>
                                <p>번거로움 없는 "it's my match" 트렌드 컬렉션은 젊은이들이 젊은이들을 위해 개발한 것으로, 4가지 다기능적이고 다재다능한 아이템으로 구성되어 있습니다. 그릇, 서빙 그릇, 머그잔, 접시는 3가지 릴리프 구조와 파우더, 그린, 화이트의 트렌드 색상으로 제공됩니다. 다양한 창의적인 방식으로 결합할 수 있으므로 모든 사람이 완벽한 불완전함의 룩을 만들 수 있는 기회를 제공합니다.</p>
                            </div>

                            <v-img src="/images/company/innovation/awards/image7.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Metric Art</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">Metric Art 세라믹 주방 싱크대는 2011년 인테리어 혁신상을 수상했습니다. 이 싱크대의 디자인은 명확한 선, 단순한 모양, 줄어든 반경을 중심으로 합니다. 이 디자인의 주요 특징은 세면기 영역을 강조하는 눈에 띄는 테두리입니다.</p>

                            <v-img src="/images/company/innovation/awards/image8.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">ViClean-U shower-toilet</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">ViClean-U 샤워 변기는 2014년 iF 제품 디자인 어워드에서 수상한 제품 중 하나입니다. 이 혁신적인 변기는 다양한 사용자 정의 기능 덕분에 부드럽고 친밀한 세척을 제공합니다. 물의 온도와 압력을 조절할 수 있으며 노즐의 위치를 ​​조정할 수 있습니다. 다양한 온도 설정의 따뜻한 공기 흐름은 쾌적한 건조를 제공합니다.</p>

                            <v-img src="/images/company/innovation/awards/image9.jpg" class="mt-40px mt-xl-80px" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "회사",
                href: "/company/about/figures",
            },
            {
                text: "품질과 혁신",
                href: "/company/innovation/products",
            },
            {
                text: "수상경력",
                href: "/company/innovation/awards",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -24px;
                    bottom: -184px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 40px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
            }
            &--right {
                transform: translateY(-160px);
            }
        }
    }
}

// Animations
@keyframes verticalLine {
    from {
        height: 0;
    }
    to {
        height: 120px;
    }
}
@keyframes verticalTitle {
    from {
        transform: translateY(-40px) rotate(90deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
    }
}
</style>