<template>
    <v-row justify="center" align="center" class="row--lg">
        <v-col order-md="2" cols="12" md="auto">
            <v-sheet max-width="700" color="transparent">
                <swiper class="swiper" v-bind="{ options }">
                    <template v-for="item in items">
                        <swiper-slide :key="item">
                            <img :src="`${path}/${item}`" alt="" class="d-block" />
                        </swiper-slide>
                    </template>
                </swiper>
                <div class="swiper-pagination" />
            </v-sheet>
        </v-col>
        <v-col order-md="1" cols="auto">
            <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--prev">
                <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
            </v-btn>
        </v-col>
        <v-col order="3" cols="auto">
            <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--next">
                <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        path: { type: String },
        items: { type: String },
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.swiper-pagination {
    position: static;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    ::v-deep {
        .swiper-pagination-bullet {
            background-color: rgba(0, 0, 0, 0.2) !important;

            &-active {
                background-color: #007aff !important;
            }
        }
    }
}
</style>