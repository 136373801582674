var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "src": "/images/company/about/vision/visual.jpg"
    }
  }, [_c('div', {
    staticClass: "vertical-title"
  }, [_vm._v("Vision & Mission")])]), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("Vision & Mission")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("공통된 목표. Villeroy & Boch에서는 항상 독특한 도자기로 개인적인 경험을 만드는 방법을 알고 있었습니다. 새로운 기준을 계속 설정하려는 열정에 힘입어, 미래 지향적인 접근 방식을 통해 미래를 위한 창의적이고 혁신적인 솔루션을 개발할 수 있습니다. 그 결과 고객이 집처럼 느낄 수 있는 환경이 조성되었습니다.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "sub-sheet sub-sheet--left",
    attrs: {
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("우리의 비전: 집을 집답게")]), _c('p', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_vm._v("\"집\"은 그 집에 사는 사람들의 개인적 비전과 스타일을 반영하는 방식으로 집이 꾸며질 때만 만들어집니다. 우리는 모든 사람을 위해, 모든 곳에서 이것이 실현되도록 돕습니다.")]), _c('v-img', {
    attrs: {
      "src": "/images/company/about/vision/image1.jpg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "sub-sheet sub-sheet--right",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/company/about/vision/image2.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("우리의 미션: 프리미엄 세라믹을 사용하여 기분 좋은 순간과 공간을 창조합니다.")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v("항상 우리의 노력의 최전선에는 고객의 요구와 희망에 대한 강력한 이해가 있습니다. 이러한 지식은 모든 가정에 특정한 특성을 창조하는 데 도움이 되는 도구가 되는 즐거움을 제공합니다. 귀하의 가정은 편안해야 하므로 우리는 제품에 모든 열정, 창의성 및 디자인 전문 지식을 주입하고 이를 기술 및 개념적 노하우와 결합합니다. 우리의 브랜드, 라이프스타일 및 가격 세그먼트를 통해 귀하의 가정을 위한 뛰어난 개별 품목과 완벽한 완전한 솔루션을 모두 제공합니다.")]), _c('p', [_vm._v("세라믹은 우리의 강점이며 우리의 유전자에 있습니다. 가능한 한, 우리는 다른 소재보다 세라믹의 우수한 품질에 의존합니다. 세라믹에 대한 우리의 전문성과 열정은 벤치마크를 설정하고 항상 각 세그먼트에서 최고의 품질과 혁신을 브랜드로 대표하기 위해 우리를 앞으로 나아가게 합니다.")]), _c('p', [_vm._v("세라믹과 디자인 전문 지식을 활용하여 보완적 제품 분야의 다각화 가능성을 바탕으로 고객이 처음부터 매일 새롭게 집처럼 느낄 수 있는 생활 환경을 조성합니다.")])])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }