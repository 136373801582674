var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [!_vm.loading && !_vm.html ? [_c('v-sheet', {
    staticClass: "mb-n16"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_c('span', [_vm._v("404 ")])]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_vm._v(" 요청하신 자료를 찾을 수 없습니다. ")])], 1)] : [_c('link', _vm._b({
    attrs: {
      "rel": "stylesheet",
      "media": "all"
    }
  }, 'link', {
    href: _vm.href
  }, false)), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.html)
    }
  })], _c('v-btn', {
    attrs: {
      "fab": "",
      "x-large": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "primary"
    },
    on: {
      "click": _vm.toggleLanguage
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 ml-n4 mr-n2"
  }, [_vm._v("mdi-swap-horizontal")]), _vm.language == 'ko' ? [_c('v-icon', {
    staticClass: "ml-n2 mr-n4",
    attrs: {
      "x-large": ""
    }
  }, [_vm._v(" mdi-alpha-e ")])] : _vm._e(), _vm.language == 'en' ? [_c('v-icon', {
    staticClass: "ml-n2 mr-n4",
    attrs: {
      "x-large": ""
    }
  }, [_vm._v(" mdi-alpha-k ")])] : _vm._e()], 2)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "light": "",
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5",
      "color": "primary"
    }
  })], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }