var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.wide ? _c('section', {
    staticClass: "collection-visual"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.image
    }
  }), _c('v-container', [_c('v-sheet', {
    attrs: {
      "color": "white"
    }
  }, [_c('v-breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#333"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }], null, false, 1409474385)
  }), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('div', {
    staticClass: "collection-visual__title",
    class: {
      'scrolled-top': _vm.isScrolledTop
    }
  }, [_c('div', {
    staticClass: "txt txt--xs font-weight-light"
  }, [_vm._v("COLLECTION")]), _c('h2', {
    staticClass: "tit tit--lg font-tertiary font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])])])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }