var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('collections-visual', {
    attrs: {
      "image": `${_vm.path}/visual.webp`,
      "title": "ViClean",
      "breadcrumbs": _vm.breadcrumbs
    }
  }), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("화장실에 갈 때마다 깨끗하고 상쾌한 기분을 느껴 보세요. Villeroy & Boc의 ViClean 비데 변기 컬렉션은 부드럽게 앞뒤로 분사되는 워터 제트로 최적의 청소, 쾌적한 위생 및 손쉬운 조작을 제공합니다. 세련된 디자인.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit text-center"
  }, [_vm._v("당신의 새로운 안락 지대")])])], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("제품의 장점과 특징")])]), _c('section-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.features
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("ViClean 모델을 찾아보세요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("ViClean-I 100과 ViClean-I 200의 두 가지 모델은 독특한 위생과 새로운 편안함을 보장합니다. 모든 것은 개별적으로 조정될 수 있습니다. 하이라이트: 비데 변기의 편의 기능은 외부에서 볼 수 없으며 내부에 숨겨져 있습니다.")])])], 1), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/viclean-wc-nozzle-and-toiletrim-white.webp`
    }
  }), _c('v-card-text', [_c('div', {
    staticClass: "tit tit--xs mb-6px mb-md-8px"
  }, [_vm._v("ViClean-I 100")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("ViClean-I 100은 직관적인 조작 등 위생적이고 편안한 기능 외에도 HarmonicWave 샤워젯을 통해 부드러운 파도 움직임으로 샤워 기능을 제공합니다.")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/viclean-toilet-white-with-flush-technology.webp`
    }
  }), _c('v-card-text', [_c('div', {
    staticClass: "tit tit--xs mb-6px mb-md-8px"
  }, [_vm._v("ViClean-I 200")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("ViClean-I 200은 한 단계 더 나아졌습니다. 올라운드 컴포트 패키지는 컬렉션의 기능 외에도 세탁, 드라이, 시트 난방, 사용자 인식 등의 컴포트 옵션도 제공합니다.")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("Vi-Clean-I 100 대 Vi-Clean-I 200")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("어떤 ViClean 비데 변기를 선택하시든 탁월한 선택입니다! 표에서 두 모델을 빠르고 쉽게 비교할 수 있습니다.")])])], 1)], 1), _c('div', {
    staticClass: "position-relative"
  }, [_c('v-sheet', {
    staticClass: "position-sticky px-16px px-md-40px",
    attrs: {
      "color": "primary"
    }
  }, [_c('v-row', {
    staticClass: "row--lg white--text tit tit--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("비데 변기 모델")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("ViClean-I 100")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("ViClean-I 200")])], 1)], 1), _c('v-sheet', {
    staticClass: "txt mt-16px mt-md-20px px-16px px-md-20px"
  }, [_vm._l(_vm.table, function (item, index) {
    return [_c('div', {
      key: index,
      class: index == 0 ? 'mb-0' : 'mt-56px mt-md-100px'
    }, [_c('v-row', {
      staticClass: "row--lg"
    }, [_c('v-col', {
      staticClass: "font-weight-medium",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "pl-0px pl-md-20px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])]), _c('v-col', {
      staticClass: "pa-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider')], 1)], 1), _vm._l(item.children, function (child) {
      return [_c('v-row', {
        key: child.text,
        staticClass: "row--lg",
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "6"
        }
      }, [_c('div', {
        staticClass: "pl-0px pl-md-20px"
      }, [_vm._v(" " + _vm._s(child.text) + " ")])]), child.check ? [_c('v-col', {
        staticClass: "text-center",
        attrs: {
          "cols": "3"
        }
      }, [_c('v-icon', [child.one == true ? [_vm._v("mdi-check")] : [_vm._v("mdi-close")]], 2)], 1), _c('v-col', {
        staticClass: "text-center",
        attrs: {
          "cols": "3"
        }
      }, [_c('v-icon', [child.two == true ? [_vm._v("mdi-check")] : [_vm._v("mdi-close")]], 2)], 1)] : [_c('v-col', {
        staticClass: "text-center",
        attrs: {
          "cols": "3"
        }
      }, [_vm._v(" " + _vm._s(child.one) + " ")]), _c('v-col', {
        staticClass: "text-center",
        attrs: {
          "cols": "3"
        }
      }, [_vm._v(" " + _vm._s(child.two) + " ")])], _c('v-col', {
        staticClass: "pa-0",
        attrs: {
          "cols": "12"
        }
      }, [_c('v-divider')], 1)], 2)];
    })], 2)];
  })], 2)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }