var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "max-width": "798",
      "src": "/images/company/responsibility/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("Taking responsibility")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("국제 기업으로서, 기업의 사회적 책임은 오랫동안 빌레로이앤보흐의 기업 철학의 핵심 기둥이 되어 왔습니다.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v(" 경제. 생태. 사회적 헌신. 빌레로이 앤 보흐는 지속 가능한 제품과 직원 및 동료 인간에 대한 책임을 지는 것이 회사의 의무라고 생각합니다. 원자재와 에너지의 효율적인 사용과 배출량 감축에 대한 헌신은 환경 전략의 핵심 요소입니다. 최근 몇 년 동안 폐수와 오염의 양을 크게 줄이는 동시에 생산 공정을 최적화하는 데 목표를 둔 투자를 통해 에너지 효율성을 지속적으로 개선했습니다. ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v(" 1748년으로 거슬러 올라가는 풍부한 유산을 가진 회사로서, 우리는 항상 우리의 문화적, 사회적 환경에서도 책임을 지는 것을 우리의 의무로 여겨왔습니다. 이러한 인식은 \"Villeroy & Boch - 전통 유지\"라는 사명 선언문에 기록되어 있습니다. Villeroy & Boch는 사회에 강력하고 신뢰할 수 있는 파트너입니다. 수세기 동안 우리는 특히 주요 현장에서 사람들의 생활 환경을 개선하기 위해 고안된 프로젝트와 활동을 추진해 왔습니다. 또한 우리는 직원들이 책임을 지고 지속 가능한 인적 자원 정책의 일환으로 직원 개발을 촉진하도록 격려합니다. ")])])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }