<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/about/vision/visual.jpg" class="visual-image">
                    <div class="vertical-title">Vision &amp; Mission</div>
                </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Vision &amp; Mission</div>
                    <p class="txt txt--xs line-height-16">공통된 목표. Villeroy &amp; Boch에서는 항상 독특한 도자기로 개인적인 경험을 만드는 방법을 알고 있었습니다. 새로운 기준을 계속 설정하려는 열정에 힘입어, 미래 지향적인 접근 방식을 통해 미래를 위한 창의적이고 혁신적인 솔루션을 개발할 수 있습니다. 그 결과 고객이 집처럼 느낄 수 있는 환경이 조성되었습니다.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--left">
                            <div class="tit tit--xs font-tertiary mb-20px">우리의 비전: 집을 집답게</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">"집"은 그 집에 사는 사람들의 개인적 비전과 스타일을 반영하는 방식으로 집이 꾸며질 때만 만들어집니다. 우리는 모든 사람을 위해, 모든 곳에서 이것이 실현되도록 돕습니다.</p>

                            <v-img src="/images/company/about/vision/image1.jpg" />
                        </v-sheet>
                    </v-col>

                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--right">
                            <v-img src="/images/company/about/vision/image2.jpg" />

                            <div class="tit tit--xs font-tertiary mb-20px">우리의 미션: 프리미엄 세라믹을 사용하여 기분 좋은 순간과 공간을 창조합니다.</div>
                            <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                <p>항상 우리의 노력의 최전선에는 고객의 요구와 희망에 대한 강력한 이해가 있습니다. 이러한 지식은 모든 가정에 특정한 특성을 창조하는 데 도움이 되는 도구가 되는 즐거움을 제공합니다. 귀하의 가정은 편안해야 하므로 우리는 제품에 모든 열정, 창의성 및 디자인 전문 지식을 주입하고 이를 기술 및 개념적 노하우와 결합합니다. 우리의 브랜드, 라이프스타일 및 가격 세그먼트를 통해 귀하의 가정을 위한 뛰어난 개별 품목과 완벽한 완전한 솔루션을 모두 제공합니다.</p>
                                <p>세라믹은 우리의 강점이며 우리의 유전자에 있습니다. 가능한 한, 우리는 다른 소재보다 세라믹의 우수한 품질에 의존합니다. 세라믹에 대한 우리의 전문성과 열정은 벤치마크를 설정하고 항상 각 세그먼트에서 최고의 품질과 혁신을 브랜드로 대표하기 위해 우리를 앞으로 나아가게 합니다.</p>
                                <p>세라믹과 디자인 전문 지식을 활용하여 보완적 제품 분야의 다각화 가능성을 바탕으로 고객이 처음부터 매일 새롭게 집처럼 느낄 수 있는 생활 환경을 조성합니다.</p>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "회사",
                href: "/company/about/figures",
            },
            {
                text: "빌레로이앤보흐",
                href: "/company/about/figures",
            },
            {
                text: "비전과 미션",
                href: "/company/about/vision",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            > .v-image {
                margin-top: 40px;
            }
        }
        &--right {
            > .v-image {
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -84px;
                    bottom: -242px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 40px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                > .v-image {
                    margin-top: 80px;
                }
            }
            &--right {
                transform: translateY(-160px);
                > .v-image {
                    margin-bottom: 80px;
                }
            }
        }
    }
}

// Animations
@keyframes verticalLine {
    from {
        height: 0;
    }
    to {
        height: 120px;
    }
}
@keyframes verticalTitle {
    from {
        transform: translateY(-40px) rotate(90deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
    }
}
</style>