<template>
    <client-page>
        <collections-visual :image="`${path}/visual.webp`" title="Loop & Friends" :breadcrumbs="breadcrumbs" />

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--sm text-center">눈을 감고 가장 좋아하는 장소를 상상해 보세요. 방랑벽을 느끼시나요? 그렇다면 그것이 당신에게 영감을 주고 꿈의 목적지를 당신의 욕실로 가져오도록 하세요! 환상적으로 들리나요? 루프앤프렌즈 입니다. Villeroy & Boch의 다용도 욕실 컬렉션을 사용하면 욕실을 매우 개인적인 웰빙의 오아시스로 변화시킬 수 있습니다.</div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <v-row justify="center" class="row--lg">
                        <v-col cols="12" md="8" class="text-center">
                            <div class="tit mb-12px mb-md-16px">Loop & Friends 제품군을 살펴보세요</div>
                            <div class="txt txt--sm">눈을 감고 가장 좋아하는 장소를 상상해 보세요. 방랑벽을 느끼시나요? 그렇다면 그것이 당신에게 영감을 주고 꿈의 목적지를 당신의 욕실로 가져오도록 하세요! 환상적으로 들리나요? 루프앤프렌즈 입니다. Villeroy & Boch의 다용도 욕실 컬렉션을 사용하면 욕실을 매우 개인적인 웰빙의 오아시스로 변화시킬 수 있습니다.</div>
                        </v-col>
                    </v-row>
                </div>

                <idea-product-list :path="path" :items="products" />
            </v-container>
        </page-section>

        <page-section>
            <v-img :src="`${path}/loop-and-friends-washbasin-in-white-bathroom-santorinian-style.webp`" />
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap text-center">
                    <div class="tit">모든 Loop & Friends 제품</div>
                </div>
                <v-tabs centered class="mb-20px mb-md-40px" v-model="tab">
                    <template v-for="item in tabs">
                        <v-tab :key="item.tab">
                            <v-btn large class="rounded-xs">
                                {{ item.tab }}
                            </v-btn>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <template v-for="item in products">
                        <v-tab-item :key="item.tab">
                            <v-row>
                                <v-col cols="6" sm="4" lg="3">
                                    <shop-product-card />
                                </v-col>
                            </v-row>

                            <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                        </v-tab-item>
                    </template>
                </v-tabs-items>
            </v-container>

            <div class="d-flex justify-center mt-24px mt-md-32px">
                <v-btn large color="white" elevation="4">
                    모든 Loop & Friends 상품 보러가기
                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                </v-btn>
            </div>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--sm">
                    <div class="tit text-center">하나의 제품 - 다른 단계</div>
                </div>
                <center-slide :path="path" :items="steps"  />
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CollectionsVisual from "@/components/client/collections/collections-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import IdeaProductList from "@/components/client/ideas/idea-product-list.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";
import CenterSlide from '@/components/client/dumb/center-slide.vue';

export default {
    components: {
        ClientPage,
        PageSection,
        CollectionsVisual,
        SectionSlide,
        IdeaProductList,
        ShopProductCard,
        PaginationComponent,
        CenterSlide,
    },
    data: () => ({
        tab: null,
        breadcrumbs: [
            {
                text: "컬렉션",
                href: "/collections/antao",
            },
            {
                text: "Loop & Friends",
                href: "/collections/loop-friends",
            },
        ],
        path: "/images/collections/loop-friends",
        products: [
            {
                title: "피팅",
                text: "다양한 기능을 갖춘 다양한 싱크대에 대한 다양한 수전 높이",
                image: "loop-friends-washbasin-white-in-modern-light-bathroom.webp",
                cols: "5",
                button: "피팅 보기",
                link: "/",
            },
            {
                title: "세면기",
                text: "개별 가구에 맞는 클래식한 화이트 톤이나 트렌디한 색상의 다양한 모양, 크기 및 설치 변형 가능",
                image: "loop-friends-washbasin-with-black-conum-taps.webp",
                cols: "6",
                button: "세면기 보기",
                link: "/",
            },
            {
                title: "욕조",
                text: "유행을 타지 않는 매력적인 디자인의 고품질 Quaryl® 욕조",
                image: "loop-friends-bathtub-in-front-of-a-stone-wall.webp",
                cols: "12",
                button: "욕조 보기",
                link: "/",
            },
        ],
        tabs: [
            {
                tab: "피팅",
            },
            {
                tab: "세면기",
            },
            {
                tab: "욕조",
            },
        ],
        steps: [
            "loop-friends-bathtub-black-in-japanese-style-bathroom.webp",
            "loop-friends-bathtub-in-japanese-style-bathroom.webp",
            // 
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}

@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
}
</style>