var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "src": "/images/company/innovation/products/visual.jpg"
    }
  }, [_c('div', {
    staticClass: "vertical-title"
  }, [_vm._v("Innovative products")])]), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("Innovative strength since 1748")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("270년 이상 혁신은 Villeroy & Boch의 기업 문화의 일부였습니다. 혁신은 긍정적인 이미지를 구축하고 125개국 이상에서 브랜드의 성공을 이끄는 데 도움이 되었습니다. 고품질 디자인과 더불어 혁신의 기본 목표는 항상 고객을 위한 기능성과 부가가치입니다. Villeroy & Boch의 주요 혁신을 여기에서 확인할 수 있습니다.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "sub-sheet sub-sheet--left",
    attrs: {
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "quote"
  }, [_c('p', {
    staticClass: "quote__text"
  }, [_c('v-img', {
    staticClass: "quote__mark",
    attrs: {
      "src": "/images/company/innovation/products/quote-before.svg"
    }
  }), _vm._v(" 창의적 문화를 회사의 최고 경영진 차원에서 확립하고 중요한 우선 과제로 삼는 것이 매우 중요합니다. "), _c('v-img', {
    staticClass: "quote__mark",
    attrs: {
      "src": "/images/company/innovation/products/quote-after.svg"
    }
  })], 1), _c('span', {
    staticClass: "quote__from"
  }, [_vm._v("피터 델윙 박사 (Innovation Manager)")])]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/products/image1.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("DirectFlush 변기 - 지능형 엔지니어링")]), _c('p', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_vm._v(" Villeroy & Boch DirectFlush 변기는 기존의 림 대신 지능형 플러싱 시스템을 갖추고 있습니다. 변기 윗부분의 스플래시 가드와 물 분사구의 더 높은 위치 덕분에 변기 내부 표면 전체를 튀기지 않고 헹굽니다. DirectFlush는 작동당 3~4.5리터의 물만 소모하므로 최대 플러싱 효율을 보장합니다. DirectFlush 모델은 빠르고 쉽게 세척할 수 있도록 완전히 유약 처리되어 있습니다. 2015년 DirectFlush와 HygieneFlush 변기 판매량은 전년 대비 두 배로 증가했습니다. ")]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/products/image2.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("TitanCeram - 미래를 가진 소재")]), _c('p', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_vm._v(" TitanCeram은 260년 이상의 세라믹 생산 경험을 모아 고품질 프리미엄 디자인을 위해 특별히 개발된 혁신적인 소재입니다. 새로운 세라믹 소재는 특별히 조정된 생산 단계를 거쳐 고유하고 정밀한 모양의 제품을 만들 수 있습니다. TitanCeram은 매우 얇은 벽과 날카롭게 정의된 모서리가 특징인 참신하고 정밀한 모양인 Octagon pedestal washbasin과 같은 뛰어난 디자인 하이라이트를 의미합니다. ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "sub-sheet sub-sheet--right",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-img', {
    staticClass: "my-40px mt-xl-0 mb-xl-80px",
    attrs: {
      "src": "/images/company/innovation/products/image3.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("Manufacture Rock")]), _c('p', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_vm._v("Manufacture Rock 컬렉션은 수백만 년 전에 형성된 여러 겹의 암석으로 끊임없이 새로운 패턴, 구조 및 색상을 드러내는 슬레이트에서 영감을 받았습니다. 유약의 힌트와 함께 섬세한 부조로 재현된 단색의 검은색 프리미엄 도자기로 만든 식기의 생생한 슬레이트 표면은 매혹적으로 진짜 같은 느낌을 줍니다.")]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/products/image4.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("NewWave - 영원한 모양")]), _c('p', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_vm._v("Villeroy & Boch에 익숙한 사람이라면 누구나 NewWave를 알고 있을 것입니다. 2001년 출시 이후 NewWave는 Villeroy & Boch 제품군의 필수적인 부분이 되었습니다. 이 제품군을 특별하게 만드는 것: Villeroy & Boch의 NewWave 시리즈는 전통적인 둥근 모양의 도자기에서 벗어나 독창적인 파도 효과를 가진 완전히 새로운 디자인을 만들어냈습니다. 2004년, 컵을 생산하는 데 사용된 특별한 디자인과 혁신적인 다이캐스팅 공정으로 손잡이가 전체 모양에 조화롭게 통합되어 Villeroy & Boch는 NewWave 컬렉션에 대해 \"Innovationspreis der deutschen Wirtschaft\"[독일 경제 혁신상]를 수상했습니다.")]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/products/image5.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("ViClean - 변기 문화의 새로운 차원")]), _c('p', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_vm._v("위생, 편안함, 지속 가능성 및 혁신적인 디자인은 Villeroy & Boch의 샤워 변기의 설득력 있는 특징입니다. 샤워 변기는 아시아에서 변기 위생의 확립된 부분입니다. 이제 유럽 소비자에게 버튼 하나만 터치하면 부드럽고 개인화된 친밀한 케어의 이점을 확신시킬 때가 되었습니다. Villeroy & Boch는 선도적인 전문가 및 유명 디자이너와 협력하여 ViClean 컨셉에서 변기와 비데의 기능성과 미학을 모두 결합하는 데 성공했습니다.")])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }