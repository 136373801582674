<template>
    <client-page>
        <template v-if="!loading && !html">
            <v-sheet class="mb-n16">
                <v-card-title class="justify-center">
                    <span>404 </span>
                </v-card-title>
                <v-card-text class="text-center"> 요청하신 자료를 찾을 수 없습니다. </v-card-text>
            </v-sheet>
        </template>
        <template v-else>
            <link rel="stylesheet" v-bind="{ href }" media="all" />
            <div v-html="html" />
        </template>
        <v-btn fab x-large fixed bottom right color="primary" @click="toggleLanguage">
            <v-layout justify-center align-center>
                <v-icon class="mt-1 ml-n4 mr-n2">mdi-swap-horizontal</v-icon>
                <template v-if="language == 'ko'">
                    <v-icon x-large class="ml-n2 mr-n4"> mdi-alpha-e </v-icon>
                </template>
                <template v-if="language == 'en'">
                    <v-icon x-large class="ml-n2 mr-n4"> mdi-alpha-k </v-icon>
                </template>
            </v-layout>
        </v-btn>
        <v-fade-transition>
            <v-overlay v-show="loading" light color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    props: {
        code1: { type: String, default: null },
        code2: { type: String, default: null },
        code3: { type: String, default: null },
        code4: { type: String, default: null },
    },
    data: () => ({
        language: "ko",

        html_en: null,
        html_ko: null,

        loading_en: false,
        loading_ko: false,
    }),
    computed: {
        path() {
            return [this.code1, this.code2, this.code3, this.code4].filter((item) => !!item).join("/");
        },
        html() {
            switch (this.language) {
                case "en": {
                    return this.html_en;
                }
                case "ko": {
                    return this.html_ko;
                }
            }
        },
        href() {
            return "/html/css/b/${path}.css".interpolate({ path: this.path });
        },
        loading() {
            return this.loading_en || this.loading_ko;
        },
    },
    methods: {
        async init() {
            this.html_en = null;
            this.html_ko = null;

            this.loading_en = true;
            await Promise.all(
                ["en", "ko"].map(async (language) => {
                    this[`loading_${language}`] = true;
                    try {
                        const url = this.getPath(language);
                        const { data } = await this.$http.get(url);

                        if (url !== this.getPath(language)) return;
                        else this[`html_${language}`] = data;
                    } finally {
                        this[`loading_${language}`] = false;
                    }
                })
            );
        },

        toggleLanguage() {
            switch (this.language) {
                case "ko": {
                    this.language = "en";
                    break;
                }
                case "en": {
                    this.language = "ko";
                    break;
                }
            }
        },

        getPath(language) {
            return "/html/${language}/b/${path}.html".interpolate({ language, path: this.path });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        code1() {
            this.init();
        },
        code2() {
            this.init();
        },
        code3() {
            this.init();
        },
        code4() {
            this.init();
        },
        language() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;

        // 상품목록
        .css-lvn3eu,

        // 페이지네이션
        .css-1my61mc,

        // 스와이퍼 버튼
        .css-1nz7joe,
        .css-1npt2ci {
            display: none;
        }
    }
}
</style>