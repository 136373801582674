<template>
    <client-page>
        <collections-visual :image="`${path}/visual.webp`" title="Collaro" :breadcrumbs="breadcrumbs" />

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm text-center">오늘날 현대적인 디자인은 단지 멋진 외모 그 이상입니다. 오히려 Collaro는 기분이 좋아지는 시대를 초월한 아름다운 생활 공간을 만듭니다. 스타일을 정의하고 선조로 장식하면서도 집처럼 편안합니다. 눈에 띄는 것은 컬렉션의 독특한 룩을 특징짓는 대비되는 디자인 엣지입니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <v-row justify="center" no-gutters class="row--lg">
                        <v-col cols="12" md="8">
                            <div class="tit">캐릭터와 스타일의 만남</div>
                        </v-col>
                    </v-row>
                </div>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img :src="`${path}/finion-mirror-in-modern-white-bathroom.webp`" />
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="tit mb-12px mb-md-16px">시대를 초월한 세련된 욕실 컬렉션</div>
                        <p class="txt txt--sm">필리그리 효과와 편안함이 만났습니다! 독특한 형태, 고품질 소재, 실용적인 기능성이 결합된 욕실을 경험해 보세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <v-row no-gutters class="row--lg">
                        <v-col cols="12" md="8">
                            <div class="tit mb-12px mb-md-16px">Collaro 제품군을 살펴보세요</div>
                            <p class="txt txt--sm">다양한 색상, 모양, 크기와 결합된 다양한 세면대, 가구, 변기, 욕조를 통해 나만의 기분 좋은 오아시스를 창조할 수 있는 창의적인 자유를 누릴 수 있습니다.</p>
                        </v-col>
                    </v-row>
                </div>
                <idea-product-list :path="path" :items="products" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--sm text-center">
                    <div class="tit">영감을 주는 미학과 다양성</div>
                </div>
            </v-container>
            <swiper class="swiper" :options="options">
                <template v-for="image in images">
                    <swiper-slide :key="image">
                        <v-card tile flat :img="`${path}/${image}`">
                            <v-responsive :aspect-ratio="4 / 3" class="d-md-none" />
                            <v-responsive :aspect-ratio="5 / 2" class="d-none d-md-block" />
                        </v-card>
                    </swiper-slide>
                </template>
                <div slot="pagination">
                    <div class="swiper-button-prev">
                        <v-icon>mdi-chevron-left</v-icon>
                    </div>
                    <div class="swiper-button-next">
                        <v-icon>mdi-chevron-right</v-icon>
                    </div>
                </div>
            </swiper>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <div class="tit">이것은 당신의 Collaro 욕실에 잘 어울립니다</div>
                </div>
                <v-row align="center" class="row--xxl mx-30px my-md-n52px mx-md-52px">
                    <v-col cols="12" md="6" class="pa-md-52px">
                        <v-card tile flat to="/">
                            <v-img :src="`${path}/liberty-tap-chrom-on-collaro-washbasin-white.webp`" />
                            <v-card-text>
                                <div class="tit tit--sm mb-6px mb-md-8px">올바른 피팅</div>
                                <p class="txt txt--sm">리버티 피팅의 모던한 디자인과 콜라로의 독특한 곡선과 모서리가 조화로운 팀을 이루며 욕실을 아늑한 집으로 바꿔줍니다.</p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">Liberty 피팅 살펴보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-md-52px">
                        <v-card tile flat to="/">
                            <v-img :src="`${path}/universo-twistflush-bathroom-white-toilet-on-blue-green-background+(1).webp`" />
                            <v-card-text>
                                <div class="tit tit--sm mb-6px mb-md-8px">TwistFlush를 갖춘 변기</div>
                                <p class="txt txt--sm">Universo 양변기는 순수한 소용돌이력과 Collaro와 완벽하게 어울리는 순수한 디자인을 결합했습니다. 혁신적인 TwistFlush 세척 기술 덕분에 특히 철저하게 세척하고 물을 절약합니다.</p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">Universo TwistFlush를 만나보세요</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CollectionsVisual from "@/components/client/collections/collections-visual.vue";
import IdeaProductList from "@/components/client/ideas/idea-product-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        CollectionsVisual,
        IdeaProductList,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            centeredSlides: false,
            speed: 500,
            loop: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
        },
        breadcrumbs: [
            {
                text: "컬렉션",
                href: "/collections/antao",
            },
            {
                text: "Collaro",
                href: "/collections/Collaro",
            },
        ],
        path: "/images/collections/collaro",
        products: [
            {
                title: "세면기",
                text: "슬림한 윤곽과 독특한 디자인 엣지를 갖춘 다양한 모양과 크기의 Collaro 세면기는 욕실에 가벼움을 선사합니다.",
                image: "collaro-washbasin-in-modern-bathroom-with-sea-view.webp",
                cols: "12",
                button: "Collaro 세면기",
                link: "/",
            },
            {
                title: "욕실가구",
                text: "섬세한 효과와 편안함: Collaro는 넉넉한 수납 공간, 분위기 있는 조명, 슬림한 디자인이 인상적입니다.",
                image: "collaro-washbasin-white-on-wodden-vanity-unit-in-modern-bathroom.webp",
                cols: "5",
                button: "Collaro 욕실가구",
                link: "/",
            },
            {
                title: "화장실",
                text: "타협 없는 기분 좋은 느낌: 위생, 효율성 및 미학이 결합된 변기는 Collaro의 꿈의 욕실을 완성합니다.",
                image: "collaro-toilet-white.jpg.webp",
                cols: "6",
                button: "욕조 보기",
                link: "/",
            },
            {
                title: "욕조",
                text: "슬림한 실루엣의 콜라로 욕조는 세련된 외관과 편안한 휴식 공간이 환상적인 조화를 이루고 있습니다.",
                image: "collaro-bathtub-in-bathroom-with-sea-view.webp",
                cols: "12",
                button: "Collaro 욕조",
                link: "/",
            },
        ],
        images: [
            "collaro-bathroom-wc-furniture-washbasin-white.jpg (1).webp",
            "collaro-washbasin-in-floral-bathroom (1).webp",
            //
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}

@media (min-width: 768px) {
}
</style>